// 
// 
// video.scss
//
//


// Bootstrap overrides
//
// General overrides for default embed styles

.embed-responsive{
  background: $black;
}

// Video covers
//
// Element that allows embeddable media inside while keeping theme consistens styling

$video-cover-transition: .35s;

@keyframes fadeOut{
  0%{ opacity: 1; }
  100%{ opacity: 0; transform: scale(1.5); }
}

.video-cover{
  background: $black;
  position: relative;
  overflow: hidden;
  .bg-image{
    z-index: 1;
    transition: opacity $video-cover-transition ease;
  }
  .video-play-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    margin-top: -36px;
    margin-left: -36px;
    margin-bottom: 0 !important;
  }
  iframe{
    background: $black;
    transition: opacity $video-cover-transition ease .35s;
    opacity: 0;
  }
  &.video-cover-playing{
    .bg-image{
      opacity: 0;
      pointer-events: none;
    }
    .video-play-icon{
      animation: fadeOut .35s ease forwards;
      pointer-events: none;
    }
    iframe{
      opacity: 1;
    }
  }
  &:not(:last-child){
    margin-bottom: $spacer;
  }
}

.media{
  > .video-cover:first-child{
    margin-bottom: 0;
    margin-right: $spacer;
  }
}

.video-play-icon{
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 50%;
  background: $white;
  text-align: center;
  font-size: $h2-font-size;
  &:not(:last-child){
    margin-bottom: $spacer;
  }
  i{
    color: $headings-color;
    &:before{
      margin-right: 0;
    }
  }
  transition: transform .2s ease;
  &:hover{
    transform: scale(0.9);
  }
}

@include media-breakpoint-down(xs) {
  .fullwidth-split-image{
    .bg-image + .video-play-icon{
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}