// 
// 
// flickity.scss
//
//


@import "../plugins/flickity";

// Flickity overrides

.carousel-cell[class*='col']{
  padding: 0 $spacer/2;
  >*{
    transition: opacity .35s ease, transform .35s ease;
  }
  &:not(.is-selected){
    >*{
      opacity: .2;
    }
  }
}

.bg-dark{
  .flickity-page-dots .dot{
    background: $gray-600;
  }
}

.flickity-prev-next-button{
  border-radius: $border-radius;
  height: $spacer*2;
  width: $spacer*2;
  svg{
    display: none;
  }
  &:after{
    content: '\e83c';
    color: $headings-color;
    font-family: 'entypo';
  }
  &.next{
    &:after{
      content: '\e83d';
    }
  }
}

.main-carousel{
  > .carousel-cell{
    height: 0;
    display: none;
  }
}

.overflow-visible{
  z-index: 0;
  .flickity-viewport{
    overflow: visible;
  }
  .carousel-cell[class*='col']{
    &:not(.is-selected){
      >*{
        opacity: .05;
        pointer-events: none;
        transform: scale(0.9);
      }
    }
  }
}

.bg-dark{
  .carousel-cell{
    .card{
      border-color: theme-color("dark");
      background-color: $gray-900;
      .card-img-top{
        background-color: $gray-900;
      }
      .card-footer, .card-body, .card-header{
        background: $body-bg;
      }
    }
  }
}